import { useState, useEffect } from 'react';
import HeaderTab from './Pages/CommonComponents/HeaderTab';
import HomePage from './Pages/HomePage/HomePage';
import { init } from './api/axios';
import { CandidateService } from './Services/CandidateService';
import FullPageCenteredSpinner from './Pages/CommonComponents/FullPageCenteredSpinner';
import { withAlertSnackBar } from './Components/MessageBanner/AlertSnackBarHOC';
import constants from './Utils/Constants';
import { getProperName } from './Utils/Util';
import { Col, Flex } from '@amzn/stencil-react-components/layout';
import { PhoService } from './Services/PhoServices';
import { DEFAULT_SUPPORTED_LANGUAGES, getCandidatePortalUrl, getSupportedLanguages } from './Utils/CountryConstant';
import { useLocalStorage } from './hooks/useLocalStorage';
import useArb from './hooks/useArb';
import LanguageModal from './Components/Modals/LanguageModal';

function App(props: any) {
  const { t, isLoading: translationIsLoading } = useArb('appStrings');
  const [loader, setLoader] = useState(true);
  const [isOpenModal, setIsOpenModel] = useState(false);
  const [showModules, setShowModules] = useState(false);

  const [localeLocalStorage] = useLocalStorage('locale', null);
  const [, setGrievanceFeatureFlag] = useLocalStorage(constants.FEATURE_FLAGS.GRIEVANCE_REPORTING, false);
  const [, setSupportedLanguagesFeatureFlag] = useLocalStorage(
    constants.FEATURE_FLAGS.SUPPORTED_LANGUAGES,
    DEFAULT_SUPPORTED_LANGUAGES
  );

  //call vendor portal proxy to authenticate the user & if fails then redirect it to candidatePortal!
  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      if (init) await init();
      await CandidateService.verifySession()
        .then(async (data) => {
          const session = data.data.session;
          if (!session) redirectToCandidatePortal(sessionStorage.getItem('stage'));
          else {
            await getCandidateProfile({
              email: sessionStorage.getItem('candidateId'),
            });
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          /* istanbul ignore next */
          window.setTimeout(() => redirectToCandidatePortal(sessionStorage.getItem('stage')), 1500);
        });
    }
    if (!translationIsLoading) {
      const pageUrl = new URL(document.URL);
      const paths = pageUrl.pathname;
      const searchParams = new URLSearchParams(window.location.search);
      if (sessionStorage.getItem('idtoken') === 'null' || sessionStorage.getItem('accesstoken') === null) {
        sessionStorage.setItem('site', paths.split('/')[1]);
        sessionStorage.setItem('role', paths.split('/')[2]);
        sessionStorage.setItem('idtoken', searchParams.get('idtoken') as string);
        sessionStorage.setItem('accesstoken', searchParams.get('accesstoken') as string);
        sessionStorage.setItem('phoId', searchParams.get('phoId') as string);
        sessionStorage.setItem('candidateId', searchParams.get('candidateId') as string);
        sessionStorage.setItem('applicationId', searchParams.get('applicationId') as string);
        reportUsername(searchParams.get('candidateId') as string);
      }

      fetchData();
    }
    // eslint-disable-next-line
  }, [translationIsLoading]);

  const getAppConfigData = async () => {
    PhoService.getAppConfig()
      .then((res) => {
        const supportedLanguages = getSupportedLanguages(res?.data?.PHO_LANGUAGES);
        setSupportedLanguagesFeatureFlag(supportedLanguages);
        setGrievanceFeatureFlag(!!res?.data?.FEATURE_FLAG_ENABLE_GRIEVANCE_REPORTING);
      })
      .catch((err) => {
        /* istanbul ignore next */
        props.snackbarShowMessage(t('PHO-error-server-failed', 'Something went wrong.'), 'error', '3000');
      });
  };

  const getCandidateProfile = async (body: { email: string | null }) => {
    setLoader(true);
    CandidateService.getCandidateProfileByEmail(body)
      .then((res) => {
        const body = res && res.data;
        setLoader(false);
        sessionStorage.setItem('candidateName', getProperName(body));
        getAppConfigData();
        // Show language selector if locale has not been set
        if (!localeLocalStorage) {
          setIsOpenModel(true);
        }
        setShowModules(true);
      })
      .catch((err) => {
        setLoader(false);
        props.snackbarShowMessage(
          t('PHO-error-profile-failed', 'Something went wrong, unable to fetch profile data.'),
          'error',
          '3000'
        );
      });
  };

  const redirectToCandidatePortal = (stage: any) => {
    props.snackbarShowMessage(
      t(
        'PHO-error-authentication-failed',
        'User session is not verified please login in candidate portal & redirect back...'
      ),
      'error'
    );
    const candidatePortalURL = getCandidatePortalUrl(stage);
    window.setTimeout(() => {
      window.location.href = candidatePortalURL;
      sessionStorage.clear();
    }, 2500);
  };

  if (loader) {
    /* istanbul ignore next */
    return <FullPageCenteredSpinner loadingText={t('PHO-loading-user-session', 'Checking user session...')} />;
  }

  return (
    <Col height="100vh">
      <HeaderTab />
      <Flex flexGrow={1} flexDirection="row">
        {showModules && <HomePage />}
      </Flex>
      <LanguageModal
        isOpen={isOpenModal}
        shouldCloseOnClickOutside={false}
        close={() => {
          setIsOpenModel(false);
        }}
      />
    </Col>
  );
}
/* istanbul ignore next */
function reportUsername(username: string): void {
  if (username !== null && username !== '') {
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: username,
      },
    });
    document.dispatchEvent(authenticatedUserEvent);
  }
}

export default withAlertSnackBar(App);
