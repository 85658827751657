import { Col } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerProps, SpinnerSize } from '@amzn/stencil-react-components/spinner';

function FullPageCenteredSpinner(props: SpinnerProps) {
  return (
    <Col height="100vh" alignItems="center" justifyContent="center" style={{ zIndex: '9999' }}>
      <Spinner showText size={SpinnerSize.Medium} {...props} />
    </Col>
  );
}

export default FullPageCenteredSpinner;
