import { View } from '@amzn/stencil-react-components/layout';
import { IconCheckCircleFill, IconPadlock } from '@amzn/stencil-react-components/icons';
import constants from '../../Utils/Constants';

interface MenuIconProps {
  status: string;
}

export default function MenuIcon({ status }: MenuIconProps) {
  if (status === constants.PHO.STATUS.COMPLETED) {
    return <IconCheckCircleFill color="green70" />;
  }
  if (status === constants.PHO.STATUS.TODO) {
    return (
      <View color="neutral40">
        <IconPadlock />
      </View>
    );
  }
  return <></>;
}
