import React, { useState, useRef } from 'react';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { View } from '@amzn/stencil-react-components/layout';

export const withAlertSnackBar = (WrappedComponent) => {
  return (props) => {
    const childFunc = useRef(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] = useState(MessageBannerType.Success);

    const showMessage = (message, severity = 'error', duration = 2000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    return (
      <React.Fragment>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} childFunc={childFunc} />
        {open && (
          <View
            width="80%"
            alignSelf="center"
            style={{ position: 'fixed', top: '4rem', left: '50%', transform: 'translateX(-50%)', zIndex: '999999' }}
          >
            <MessageBanner
              isDismissible
              autoDismissAfter={duration}
              onDismissed={() => {
                setOpen(!open);
              }}
              type={severity}
            >
              <>{message}</>
            </MessageBanner>
          </View>
        )}
      </React.Fragment>
    );
  };
};
