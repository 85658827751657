import { Locale } from '../hooks/useArb';

export const CANDIDATE_PORTAL_BASE_URL = {
  alpha: {
    AE: 'https://alpha.careers.warehousejobsuae.com/',
    SA: 'https://alpha.careers.warehousejobsksa.com/',
    EG: 'https://alpha.careers.warehousejobseg.com/',
    IN: 'https://alpha.careers.hvhindia.in/',
  },
  beta: {
    SA: 'https://beta.careers.warehousejobsksa.com/',
    EG: 'https://beta.careers.warehousejobseg.com/',
    AE: 'https://beta.careers.warehousejobsuae.com/',
    IN: 'https://beta.careers.hvhindia.in/',
  },
  gamma: {
    SA: 'https://gamma.careers.warehousejobsksa.com/',
    AE: 'https://gamma.careers.warehousejobsuae.com/',
    EG: 'https://gamma.careers.warehousejobseg.com/',
    IN: 'https://gamma.careers.hvhindia.in/',
  },
  prod: {
    SA: 'https://careers.warehousejobsksa.com/',
    AE: 'https://careers.warehousejobsuae.com/',
    EG: 'https://careers.warehousejobseg.com/',
    IN: 'https://careers.hvhindia.in/',
  },
};
export let isMENACountry = () => {
  const countryCode = sessionStorage.getItem('countryCode');
  return countryCode === 'AE' || countryCode === 'EG' || countryCode === 'SA';
};

export function getCandidatePortalUrl(stage: string) {
  const countryCode = sessionStorage.getItem('countryCode');
  // @ts-ignore
  return CANDIDATE_PORTAL_BASE_URL[stage][countryCode];
}

// SR Grievance Ingress Point SOP: https://quip-amazon.com/OKwIAuzab2cy/SR-Grievance-Ingress-Point-SOP
export const GRIEVANCE_CHATBOT_BASE_URL = {
  alpha: {
    SA: 'https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    AE: 'https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    EG: 'https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
  },
  beta: {
    SA: 'https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    AE: 'https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    EG: 'https://gamma-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
  },
  gamma: {
    SA: 'https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    AE: 'https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    EG: 'https://preprod-mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
  },
  prod: {
    SA: 'https://mena.fra.chat.hvh.a2z.com/?language=English&country=Saudi%20Arabia&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    AE: 'https://mena.fra.chat.hvh.a2z.com/?language=English&country=United%20Arab%20Emirates&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
    EG: 'https://mena.fra.chat.hvh.a2z.com/?language=English&country=Egypt&type=Associate%20/%20Driver%20/%20Marshall%20/%20Executive%20/%20Administrator%20/%20Cashier&ingressPoint=PHO',
  },
};

export function getGrievanceChatbotUrl() {
  const stage = sessionStorage.getItem('stage');
  const countryCode = sessionStorage.getItem('countryCode');
  // @ts-ignore
  return GRIEVANCE_CHATBOT_BASE_URL?.[stage]?.[countryCode];
}

type Country = 'AE' | 'SA' | 'EG' | 'IN';

export const DEFAULT_SUPPORTED_LANGUAGES: Locale[] = ['en-US'];

export const LANGUAGES: Partial<Record<Locale, { shortName: Locale; longName: string }>> = {
  'en-US': { shortName: 'en-US', longName: 'English' },
  'ar-AE': { shortName: 'ar-AE', longName: 'عربي' },
  'hi-IN': { shortName: 'hi-IN', longName: 'हिंदी' },
};

export const LANGUAGES_BY_COUNTRY: Record<Country | 'other', Locale[]> = {
  AE: ['en-US', 'ar-AE'],
  SA: ['en-US', 'ar-AE'],
  EG: ['en-US', 'ar-AE'],
  IN: ['en-US', 'hi-IN'],
  other: DEFAULT_SUPPORTED_LANGUAGES,
};

// Retrieve language options based on country and supported-languages (AppConfig allow-list)
export function getLanguageOptions(country: Country, supportedLanguages: Locale[]) {
  const languages = LANGUAGES_BY_COUNTRY?.[country] ?? LANGUAGES_BY_COUNTRY['other'];
  // Filter supported languages based on App-Config Allow-list
  const filterLanguages = languages.filter((locale) => supportedLanguages.includes(locale));
  // Retrieve Language Objects from keys
  return filterLanguages.map((locale) => LANGUAGES[locale]);
}

/**
 * Converts locale string format
 * @param locale - Hypen-delimited or Underscore_Delimited Locale Strings
 * @param format - Hypen-delimited is used by ARB-Tools & Stencil. Underscore_delimited is used by AppConfig & APIs.
 */
export function convertLocaleFormat(locale: string, format: 'hypen' | 'underscore') {
  if (format === 'hypen') {
    return locale.replace('_', '-');
  }
  return locale.replace('-', '_');
}

// Retrieve supported languages from appConfig language value
export function getSupportedLanguages(appConfigLanguages: Record<string, string>) {
  const supportedLanguages = Object.keys(appConfigLanguages ?? {});
  if (!supportedLanguages.length) {
    return DEFAULT_SUPPORTED_LANGUAGES;
  }
  // App-Config Language values are '_' delimited. Replace with '-' delimited used by Stencil / Arb-tools.
  return supportedLanguages.map((language) => convertLocaleFormat(language, 'hypen'));
}
