import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Container, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { IconAlert } from '@amzn/stencil-react-components/icons';
import useArb from '../../hooks/useArb';
import { LiveRegion, LiveRegionRole } from '@amzn/stencil-react-components/live-announcer';

interface IPlayerScreenProps {
  altText: string;
  path: string;
}

export default function PlayerScreen(props: IPlayerScreenProps) {
  const { t } = useArb('appStrings');

  const mediaUrl = `${sessionStorage.getItem('cloudfrontS3Distribution')}/${props.path}`;

  const [isError, setError] = useState(false);

  useEffect(() => {
    // Media url exists, but cannot be played
    if (mediaUrl && !ReactPlayer.canPlay(mediaUrl)) {
      setError(true);
    }
  }, [mediaUrl]);

  return (
    <Container alignItems="center">
      <div>
        <ReactPlayer
          data-testId="video-player"
          width="100%"
          height="100%"
          url={mediaUrl}
          playing
          controls
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
          onError={(err) => {
            setError(true);
          }}
        />
      </div>

      <LiveRegion aria-live="polite" role={LiveRegionRole.Status} aria-atomic="true">
        {isError && (
          <Row columnGap={8} alignItems="center">
            <IconAlert color="neutral70" aria-hidden="true" />
            <Text fontSize="T200" color="neutral70">
              {t('PHO-error-video-playback', 'Unable to stream video content')}: {props.altText}
            </Text>
          </Row>
        )}
      </LiveRegion>
    </Container>
  );
}
