import { Col, ColProps } from '@amzn/stencil-react-components/layout';
import MenuItem from './MenuItem';
import { styledWithTheme } from '@amzn/stencil-react-components/context';

interface SideNavProps extends ColProps {
  modules: Array<IModule>;
  isMobile: boolean;
  activeMenu: IModule;
  activeSubMenu: IContent;
  hideSelection: boolean;
}

const StyledCol = styledWithTheme(Col)<{ isMobile: boolean }>(({ isMobile }) => ({
  width: '100%',
  zIndex: 4,
  height: '100%',
  borderInlineEnd: '1px solid #8B96A3',
  background: 'rgb(255 255 255 / 25%)',

  ...(isMobile && {
    height: 'min-content',
    boxShadow: '0px 15px 10px -15px #111',
    minHeight: '',
    borderInlineEnd: 'none',
    background: '#ffff',
  }),
}));

function SideNavBar({ modules, isMobile, activeMenu, activeSubMenu, hideSelection, ...props }: SideNavProps) {
  return (
    <StyledCol isMobile={isMobile} {...props}>
      {modules.map((menu: IModule) => (
        <MenuItem
          key={menu.moduleId}
          menu={menu}
          isActive={activeMenu?.moduleId === menu.moduleId && !hideSelection}
          activeSubMenuId={activeSubMenu?.contentId || ''}
          hideSelection={hideSelection}
        />
      ))}
    </StyledCol>
  );
}

export default SideNavBar;
